import { useEffect } from 'react'
import { isAndroid, isIOS } from 'react-device-detect'
import { navigate } from 'gatsby'

export default function appDownloadRedirect() {
  useEffect(() => {
    if (isAndroid) {
      navigate('https://play.google.com/store/apps/details?id=jp.su.pay')
    } else if (isIOS) {
      navigate('https://itunes.apple.com/jp/app/id1618013075?mt=8')
    } else {
      navigate('/')
    }
  }, [])

  return
}
